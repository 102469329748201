import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Awards from './awards'
import Press from './press'

export const fragment = graphql`
  fragment AboutAwardsPressFragment on wordpress__PAGEAcf {
    awards {
      title
      awards {
        award
      }
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    press {
      title
      press {
        link
        publication
      }
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const Intro = ({ awards, press }) => (
  <Outer>
    <Awards title={awards.title} awards={awards.awards} background={awards.background} />
    <Press title={press.title} press={press.press} background={press.background} />
  </Outer>
)

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default Intro
