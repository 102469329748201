import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { Waypoint } from 'react-waypoint'
import { motion, AnimatePresence } from 'framer-motion'
import Parallax from 'kota-react-rellax'
import { staggeredFadeInUp, fadeIn, fadeInUp } from '../animation/animations'
import { aspectRatio } from '../../helpers/aspect-ratio'

import Title from '../title'
import CloseSvg from '../../images/close.svg'
import ArrowRightSvg from '../../images/arrow-right.svg'
import ArrowLeftSvg from '../../images/arrow-left.svg'
import LogoSvg from '../../images/logo.svg'

import 'react-id-swiper/lib/styles/css/swiper.css'

export const fragment = graphql`
  fragment AboutTeamFragment on wordpress__PAGEAcf {
    team {
      title
      people {
        name
        title
        bio
        photo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const Team = ({ title, people, background }) => {
  const [mainSwiper, getMainSwiper] = useState(null)
  const [bulletSwiper, getBulletSwiper] = useState(null)
  const [activePerson, setActivePerson] = useState(null)
  const [mounted, setMounted] = useState(null)

  const goNext = () => {
    if (mainSwiper !== null) {
      mainSwiper.slideNext()
    }
  }

  const goPrev = () => {
    if (mainSwiper !== null) {
      mainSwiper.slidePrev()
    }
  }

  const goToSlide = useCallback(
    slideIndex => {
      if (mainSwiper !== null) {
        mainSwiper.slideToLoop(slideIndex)
      }
    },
    [mainSwiper]
  )

  const mainSwiperParams = {
    getSwiper: getMainSwiper,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    loopedSlides: 7,
    threshold: 999
  }

  const bulletSwiperParams = {
    getSwiper: getBulletSwiper,
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    loopedSlides: 7,
    slideToClickedSlide: true,
    threshold: 999,
    breakpoints: {
      1150: {
        slidesPerView: 6
      },
      880: {
        slidesPerView: 5
      },
      750: {
        slidesPerView: 3
      }
    }
  }

  useEffect(() => {
    if (mainSwiper !== null && mainSwiper.controller && bulletSwiper !== null && bulletSwiper.controller) {
      mainSwiper.controller.control = bulletSwiper
      bulletSwiper.controller.control = mainSwiper
    }
  }, [mainSwiper, bulletSwiper, activePerson])

  useEffect(() => {
    goToSlide(activePerson)
  }, [activePerson, goToSlide])

  const [modalActive, setModalActive] = useState(false)
  const [animation, setAnimation] = useState(undefined)

  const handleModalClose = () => {
    setModalActive(false)
  }

  const handleAnimation = () => {
    setAnimation(true)
  }

  const handleModalOpen = i => {
    setModalActive(true)
    setActivePerson(i)
  }

  const activateParallax = () => {
    setMounted(true)
  }

  return (
    <>
      <Outer className="Outer">
        <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
        <Waypoint onEnter={() => activateParallax()} scrollableAncestor="window" bottomOffset="0" />
        <Inner className="container">
          <Title type="h2" text={title} />
          <People initial="hidden" animate={animation ? 'visible' : 'hidden'} variants={staggeredFadeInUp.parent}>
            {people.map((person, i) => (
              <Person onClick={() => handleModalOpen(i)} variants={staggeredFadeInUp.child} key={i}>
                {mounted && (
                  <Parallax center speed={Math.floor(Math.random() * 4) + 1}>
                    <PhotoContainer>
                      <PhotoOverlay whileHover={{ opacity: 1 }}>+</PhotoOverlay>
                      <motion.div whileHover={{ scale: 1.1 }}>
                        <Photo fluid={person.photo.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
                      </motion.div>
                    </PhotoContainer>
                    <Name>{person.name}</Name>
                    <JobTitle>{person.title}</JobTitle>
                  </Parallax>
                )}
              </Person>
            ))}
          </People>
        </Inner>
        <BackgroundImageContainer>
          <Img fluid={background.localFile.childImageSharp.fluid} />
        </BackgroundImageContainer>
      </Outer>

      <AnimatePresence>
        {modalActive && (
          <ModalOuter>
            <Close onClick={handleModalClose} whileHover={{ rotate: 90, scale: 1.2 }} variants={fadeIn} exit="exit">
              <CloseIcon />
            </Close>
            <ModalInner initial="hidden" animate={modalActive ? 'visible' : 'hidden'} exit="exit" variants={fadeInUp}>
              <MainSwiper className="main-swiper">
                <ArrowLeft onClick={() => goPrev()} whileHover={{ scale: 1.2 }} variants={staggeredFadeInUp.child} exit="exit">
                  <ArrowLeftSvg />
                </ArrowLeft>
                <ArrowRight onClick={() => goNext()} whileHover={{ scale: 1.2 }} variants={staggeredFadeInUp.child} exit="exit">
                  <ArrowRightSvg />
                </ArrowRight>
                <Swiper {...mainSwiperParams}>
                  {people.map((person, i) => (
                    <div key={i}>
                      <ModalContentWrap>
                        <ModalPhotoOuter>
                          <div>
                            <LogoSvg />
                            <ModalPhoto fluid={person.photo.localFile.childImageSharp.fluid} className="aspect" />
                          </div>
                        </ModalPhotoOuter>
                        <ModalText>
                          <ModalName>{person.name}</ModalName>
                          <ModalTitle>{person.title}</ModalTitle>
                          <ModalBio dangerouslySetInnerHTML={{ __html: person.bio }} />
                        </ModalText>
                      </ModalContentWrap>
                      <ModalBackgroundImage fluid={person.photo.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
                    </div>
                  ))}
                </Swiper>
              </MainSwiper>
              <BulletSwiper className="bullet-swiper">
                <Swiper {...bulletSwiperParams}>
                  {people.map((person, i) => (
                    <div key={i} onClick={() => goToSlide(i)}>
                      <p>{person.name}</p>
                    </div>
                  ))}
                </Swiper>
              </BulletSwiper>
            </ModalInner>
            <ModalBackground initial="hidden" animate={animation ? 'visible' : 'hidden'} exit="exit" variants={fadeIn} />
          </ModalOuter>
        )}
      </AnimatePresence>
    </>
  )
}

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: -25%;
  left: 0;
  width: 100%;
  z-index: 1;
`

const Outer = styled.section`
  position: relative;
  padding: 10rem 0;

  @media (max-width: 750px) {
    padding: 5rem 0;
  }
`

const Inner = styled(motion.div)`
  position: relative;
  width: 100%;
  z-index: 10;

  h2 {
    font-size: 4.6rem;
    margin-bottom: 5rem;
  }
`

const People = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 650px) {
    margin-top: 10rem;
  }
`

const Photo = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.5s ease;
`

const PhotoOverlay = styled(motion.aside)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 5rem;
  opacity: 0;
  z-index: 5;
`

const PhotoContainer = styled.figure`
  overflow: hidden;

  &:hover {
    ${Photo} {
      transform: scale(1.1);
    }
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Name = styled.p`
  font-family: ${props => props.theme.fonts.san};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 1.5rem 0 0;
`

const JobTitle = styled.p`
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 0;
  opacity: 0.8;
`

const Person = styled(motion.button)`
  border: 0;
  background: 0;
  padding: 0;
  margin-bottom: 10rem;
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:nth-child(0n + 1),
  &:nth-child(0n + 10) {
    width: 29%;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(340, 465)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 2),
  &:nth-child(0n + 11) {
    width: 26%;
    margin-top: 14rem;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(300, 410)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 3),
  &:nth-child(0n + 12) {
    width: 27.5%;
    margin-top: 6rem;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(320, 445)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 4),
  &:nth-child(0n + 13) {
    width: 23.5%;
    margin-top: 14rem;
    margin-left: 6rem;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(275, 385)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 5),
  &:nth-child(0n + 14) {
    width: 26%;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(300, 410)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 6),
  &:nth-child(0n + 15) {
    width: 32%;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(375, 520)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 7),
  &:nth-child(0n + 16) {
    width: 33%;
    margin-left: 6rem;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(380, 530)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 8),
  &:nth-child(0n + 17) {
    width: 26%;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(300, 415)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(0n + 9),
  &:nth-child(0n + 18) {
    width: 23.5%;
    margin-top: 15rem;

    @media (max-width: 650px) {
      width: 48%;
      margin: 0 0 4rem;
    }

    ${PhotoContainer} {
      ${aspectRatio(275, 385)};

      @media (max-width: 650px) {
        ${aspectRatio(320, 445)};
      }
    }
  }

  &:nth-child(even) {
    @media (max-width: 650px) {
      margin-top: -10em;
      margin-bottom: 4rem;
    }
  }
`

const ModalOuter = styled.aside`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

const ModalInner = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 10;
`

const ModalContentWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 83rem;
  z-index: 5;

  @media (max-width: 850px) {
    justify-content: center;
    max-width: 50rem;
  }

  @media (max-width: 500px) {
    max-width: 70%;
  }
`

const ModalPhoto = styled(Img)`
  width: 100%;
  height: 100%;
`

const ModalBackgroundImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 1;
`

const ModalBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  will-change: opacity;
  background-color: ${props => props.theme.colours.black};
  z-index: 1;
`

const ModalPhotoOuter = styled.div`
  ${aspectRatio(315, 440)};
  width: 38%;

  svg {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    width: 78px;
    z-index: 5;
  }

  @media (max-width: 850px) {
    display: none;
  }
`

const ModalText = styled.div`
  position: relative;
  width: 55%;
  z-index: 5;

  @media (max-width: 750px) {
    width: 100%;
  }
`

const ModalName = styled.p`
  font-size: 2.8rem;
  line-height: 1.6;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
`

const ModalTitle = styled.p`
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 100;
  color: #fff;
`

const ModalBio = styled.p`
  opacity: 0.8;
`

const Close = styled(motion.button)`
  position: absolute;
  width: 2.1rem;
  height: 2.1rem;
  top: 5rem;
  right: 5rem;
  border: 0;
  background: transparent;
  padding: 0;
  will-change: transform;
  z-index: 15;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`

const CloseIcon = styled(CloseSvg)`
  width: 100%;
  height: 100%;
`

const MainSwiper = styled.div`
  position: relative;
  z-index: 5;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`

const BulletSwiper = styled.div`
  position: absolute;
  left: 0;
  bottom: 5%;
  width: 100%;
  z-index: 20;

  @media (max-width: 500px) {
    display: none;
  }

  .swiper-slide {
    text-align: center;
    width: 13%;

    p {
      font-family: ${props => props.theme.fonts.san};
      font-size: 20px;
      margin: 0;
      opacity: 0.4;
      transition: opacity 0.3s ease;
      white-space: nowrap;
      cursor: pointer;
    }

    &-active {
      p {
        opacity: 1;
      }
    }
  }
`

const ArrowLeft = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 10%;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 1200px) {
    left: 5%;
  }

  @media (max-width: 1050px) {
    right: 4rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const ArrowRight = styled(motion.div)`
  position: absolute;
  right: 10%;
  top: 50%;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 1200px) {
    right: 5%;
  }

  @media (max-width: 1050px) {
    right: 4rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default Team
