import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

import Intro from '../components/about/intro'
import Vision from '../components/about/vision'
import Team from '../components/about/team'
import Associations from '../components/about/associations'
import AwardsPress from '../components/about/awards-press'

const Home = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <Intro {...data.wordpressPage.acf.intro} />
    <Vision {...data.wordpressPage.acf.vision} />
    <Team {...data.wordpressPage.acf.team} />
    <Associations {...data.wordpressPage.acf.associations} />
    <AwardsPress awards={data.wordpressPage.acf.awards} press={data.wordpressPage.acf.press} />
  </>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...AboutIntroFragment
        ...AboutVisionFragment
        ...AboutTeamFragment
        ...AboutAssociationsFragment
        ...AboutAwardsPressFragment
      }
    }
  }
`
