import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Parallax from 'kota-react-rellax'
import { fadeInUp } from '../animation/animations'

import IconSvg from '../../images/meta-mark-gradient.svg'
import Button from '../button'

export const fragment = graphql`
  fragment AboutIntroFragment on wordpress__PAGEAcf {
    intro {
      buttonLink {
        path
      }
      buttonText
      byline
      mainContent
      title
    }
  }
`

const Intro = ({ title, byline, mainContent, buttonText, buttonLink }) => (
  <Outer>
    <Inner className="container">
      <motion.h1 initial="hidden" animate="visible" variants={fadeInUp} custom="0.5">
        {title}
      </motion.h1>
      <motion.h2 initial="hidden" animate="visible" variants={fadeInUp} custom="0.6">
        {byline}
      </motion.h2>
      <motion.p initial="hidden" animate="visible" variants={fadeInUp} custom="0.7">
        {mainContent}
      </motion.p>
      <motion.div initial="hidden" animate="visible" variants={fadeInUp} custom="0.8">
        <Button type="link" to={buttonLink.path}>
          {buttonText}
        </Button>
      </motion.div>
    </Inner>
    <Icon>
      <motion.figure initial="hidden" animate="visible" variants={fadeInUp} custom="1.2">
        <Parallax speed={4}>
          <IconSvg />
        </Parallax>
      </motion.figure>
    </Icon>
  </Outer>
)

const Outer = styled.div`
  position: relative;
  padding: 25rem 0;

  @media (max-width: 1000px) {
    padding: 20rem 0 10rem;
  }
`

const Inner = styled.div`
  position: relative;
  max-width: 66rem;
  text-align: center;
  z-index: 5;

  h1 {
    font-size: 6.6rem;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 300;
    margin: 3rem 0;
  }

  p {
    opacity: 0.8;
    margin-bottom: 3rem;
  }
`

const Icon = styled(motion.figure)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%);
  width: 45rem;
  z-index: 1;

  svg {
    width: 100%;
  }
`

export default Intro
