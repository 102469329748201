import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { motion } from 'framer-motion'
import { Waypoint } from 'react-waypoint'
import Parallax from 'kota-react-rellax'
import { staggeredFadeInUp } from '../animation/animations'
import { aspectRatio } from '../../helpers/aspect-ratio'

import Title from '../title'

const Press = ({ title, press, background }) => {
  const [animation, setAnimation] = useState(undefined)
  const handleAnimation = () => {
    setAnimation(true)
  }

  return (
    <Outer>
      <Text initial="hidden" animate={animation ? 'visible' : 'hidden'} variants={staggeredFadeInUp.parent}>
        <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
        <Title type="h3" text={title} />
        {press.map((item, i) => (
          <p key={i}>
            <motion.a href={item.link} target="_blank" rel="noopener noreferrer" variants={staggeredFadeInUp.child}>
              {item.publication}
            </motion.a>
          </p>
        ))}
      </Text>
      <BackgroundContainer>
        <ParallaxBackground center speed={1}>
          <Background fluid={background.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
        </ParallaxBackground>
      </BackgroundContainer>
    </Outer>
  )
}

const Text = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 5;

  h3 {
    font-size: 6.6rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.9;
    margin-bottom: 0;

    @media (max-width: 1000px) {
      font-size: 2rem;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;

    &:hover {
      color: ${props => props.theme.colours.primary};
    }
  }
`

const Outer = styled.div`
  ${aspectRatio(1, 1)};
  width: 50%;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 5rem 0;

    &::before {
      display: none;
    }

    ${Text} {
      position: relative;
      height: auto;
    }
  }
`

const ParallaxBackground = styled(Parallax)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const BackgroundContainer = styled.figure`
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%) scale(1.1);
  z-index: 1;

  @media (max-width: 1000px) {
    transform: translateY(-50%) scale(1.3);
  }
`

const Background = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default Press
