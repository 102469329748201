import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import { fadeInUp } from '../animation/animations'

import Title from '../title'

export const fragment = graphql`
  fragment AboutVisionFragment on wordpress__PAGEAcf {
    vision {
      title
      mainContent
    }
  }
`

const Vision = ({ title, mainContent }) => {
  const [animation, setAnimation] = useState(false)
  const handleActive = () => setAnimation(true)

  return (
    <Outer>
      <Inner className="container">
        <Waypoint onEnter={() => handleActive()} scrollableAncestor="window" bottomOffset="10%" />
        <Title type="h2" text={title} />
        <motion.p initial="hidden" animate={animation ? 'visible' : 'hidden'} variants={fadeInUp} custom="0.5">
          {mainContent}
        </motion.p>
      </Inner>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 5rem 0;
  z-index: 10;
`

const Inner = styled.div`
  padding: 0 12rem;

  @media (max-width: 650px) {
    padding: 0 4rem;
  }

  h2 {
    font-size: 6.6rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  p {
    opacity: 0.8;
    max-width: 60rem;
  }
`

export default Vision
